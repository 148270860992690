@import '@core/scss/core.scss';
@import './assets/scss/styles';

.ngx-datatable.bootstrap.core-bootstrap .datatable-header{
    height: auto !important;
}

.ngx-datatable.material .datatable-header .datatable-header-cell {
    text-align: center !important;
}

.ngx-datatable.material .datatable-body .datatable-body-cell {
    text-align: center !important;
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-body-row .datatable-body-cell {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    text-align: center !important;
    word-wrap: anywhere;
}

.bnt-min-width {min-width: 100px;}
.form-internal-heading {
    border-bottom: 1px solid #f3f2f7;
    margin-top: 15px;
    margin-bottom: 10px;
    display: inline-block;
}

label.width-80 {width: 80%;}
